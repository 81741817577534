@font-face {
  font-family: "Certificate";
  src: url("../fonts/Certificate\ Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.certificate {
  background-image: url("../images/certificate-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.certificate-wrapper {
  width: 622px;
  margin: 0 auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.cert-logo {
  height: auto;
  margin-bottom: 40px;
}

.certificate-content {
  width: 100%;
}

.lineage-item {
  position: relative;
  margin-bottom: 20px;
}

.lineage-name {
  position: relative;
  white-space: normal;
  border: 2px solid;
}

.lineage-name::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  width: 20px;
  height: 1px;
  background-color: black;
}

.lineage-item:not(:last-child) p::after {
  display: block;
}

.lineage-item:last-child p::after {
  display: none;
}

.indexNumber {
  position: absolute;
  font-size: 10px;
  top: 35px;
  left: 25%;
  padding: 0px 16px;
  background-color: black;
}

.bio-table {
  /* font-size: 0.75rem;
  border-collapse: collapse; */
  font-family: "Poppins", sans-serif;
}

.bio-table th,
.bio-table td {
  padding: 4px 6px;
}

.certificate-section {
  margin-top: 10px;
}

.certificate-title {
  font-family: "Certificate", sans-serif;
  margin-bottom: 20px;
}

.cert-subtitle {
  font-family: "Poppins", sans-serif;
  margin-bottom: 40px;
}

.lineage-table-title {
  font-size: 1.4rem;
  margin-bottom: 20px;
}

.section-title {
  margin-bottom: 5px;
  font-size: 1.25rem;
}

.certificate-footer {
  width: 100%;
}

.qr-code {
  width: 80px !important;
  height: 80px !important;
}

/* Print-specific styles */
@media print {
  .certificate {
    background-image: url("../images/certificate-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    min-height: 100vh;
    width: 100%;
  }

  .certificate-wrapper {
    width: 622px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    height: 100%;
  }

  .cert-logo {
    width: 180px;
    height: auto;
    margin-bottom: 20px;
    /* margin-top: -20px; */
  }

  .lineage-table-title {
    font-size: 0.85rem;
    margin-bottom: 20px;
  }

  .certificate-content {
    width: 100%;
    /* flex-grow: 1; */
    display: flex;
    flex-direction: column;
  }

  .lineage-item {
    position: relative;
    margin-bottom: 20px;
  }

  .lineage-name {
    position: relative;
    white-space: normal;
    border: 2px solid;
  }

  .lineage-name::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    width: 20px;
    height: 1px;
    background-color: black;
  }

  .lineage-item:not(:last-child) p::after {
    display: block;
  }

  .lineage-item:last-child p::after {
    display: none;
  }

  .indexNumber {
    position: absolute;
    font-size: 10px;
    top: 43px;
    left: 30px;
    padding: 0px 16px;
    background-color: black;
  }

  .bio-table {
    font-size: 0.75rem;
    border-collapse: collapse;
    font-family: "Poppins", sans-serif;
  }

  .bio-table th,
  .bio-table td {
    padding: 4px 6px;
  }

  .certificate-section {
    margin-top: 10px;
  }

  .certificate-title {
    font-size: 3rem;
    margin-bottom: 20px;
  }

  .cert-subtitle {
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    margin-bottom: 40px;
  }

  .section-title {
    margin-bottom: 5px;
    font-size: 1.25rem;
  }

  .certificate-footer {
    max-width: 622px;
    font-size: 0.875rem;
  }

  .qr-text {
    font-size: 8px;
  }

  .qr-code {
    width: 80px !important;
    height: 80px !important;
  }
}
